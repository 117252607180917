import _ from 'lodash';
import { connect } from 'react-redux';
import { DatasetPreview } from './DatasetPreview';
import { AppState } from 'datasetLandingPage/lib/types';
import { DatasetLandingPageEnhancedView } from 'common/types/view';

function mapStateToProps({ view: v }: AppState) {
  const view = v as DatasetLandingPageEnhancedView;
  const { id: parentViewId, name: parentViewName } = _.get(view, 'parentView') || {};

  const dataSourceDetails =
    parentViewId && parentViewName
      ? {
          parentViewName,
          parentViewId
        }
      : undefined;

  return {
    rowCount: view.rowCount,
    rowLabel: view.rowLabel,
    rowLabelMultiple: view.rowLabelMultiple,
    view: view.coreView,
    dataSourceDetails,
    isWidget: false
  };
}

export default connect(mapStateToProps)(DatasetPreview);
